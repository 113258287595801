<template>
  <section class="sections-brand-carousel no-gutter">
    <div class="container">
      <div ref="carouselWrapperRef" class="__brand-carousel-wrapper">
        <div ref="carouselItemsRef" class="__brand-carousel-inner" :style="`transform: translate(${translateX}px, 0);`">
          <div v-for="(carouselItem, key) in activeCarouselItems" :key="`carousel-item-${key}`" class="__brand-carousel-item">
            <nuxt-img :src="carouselItem.logoUrl" :alt="`${carouselItem.name} Logo`" height="40" width="100" format="webp" loading="lazy" quality="60" fit="outside" @click="openProfile(carouselItem.slug)" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang='ts'>
import {Ref} from 'vue';
import {CompanyLogoCarouselItem} from '~/@types/builder';

const props = defineProps({
  companyLogoCarousel: {
    type: Array as PropType<CompanyLogoCarouselItem[]>,
    default: () => [],
  },
});

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const translateX: Ref<number> = ref(0);
const carouselWrapperRef: Ref<HTMLDivElement | null> = ref(null);
const carouselItemsRef: Ref<HTMLDivElement | null> = ref(null);
const activeCarouselItems: Ref<CompanyLogoCarouselItem[]> = ref([]);
const fallbackCarouselItems: Ref<CompanyLogoCarouselItem[]> = ref([
  {
    name: 'Cambridge Homes',
    slug: 'cambridge-homes',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM271C/logo/fa9170ba3ba7218f4ac4bca5bdf09a68.jpg',
  },
  {
    name: 'Keith Hay Homes',
    slug: 'keith-hay-homes',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM2733/logo/2910d9fd8cc4a9f1b5de86546cdf6423.jpg',
  },
  {
    name: 'KEY2',
    slug: 'key2',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM272E/logo/04c73021c6f000e1f57a3aa69dbcecc4.png',
  },
  {
    name: 'Signature Homes',
    slug: 'signature-homes',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM2730/logo/2527c8acfb68bd6459639dfe132cc754.png',
  },
  {
    name: 'Orange Homes',
    slug: 'orange-homes',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM2723/logo/2ea0LkFXw.png',
  },
  {
    name: 'Build 7',
    slug: 'build-7',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM2716/logo/e0c3cffda2be5441cc278d1c458a329b.png',
  },
  {
    name: 'DW Homes',
    slug: 'dw-homes',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM272C/logo/736c86031e432ac725c4bdf5ffa26510.jpg',
  },
  {
    name: 'Friday Homes',
    slug: 'friday-homes',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM2724/logo/f546e457b623ca73aea1e228c9be11fa.jpg',
  },
  {
    name: 'Landmark Homes',
    slug: 'landmark-homes',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM271E/logo/0eafc497603c67f328c9b6b372d77348.png',
  },
  {
    name: 'Sentinel Homes',
    slug: 'sentinel-homes',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM272B/logo/10c8482e3224bf42382fdad201198d6f.png',
  },
  {
    name: 'Golden Homes',
    slug: 'golden-homes',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM272F/logo/7ddcd59ceee9bf66c47c863695cbfeeb.png',
  },
  {
    name: 'Maddren Homes',
    slug: 'maddren-homes',
    logoUrl: 'https://store.newhomeshub.co.nz/companies/COM271F/logo/d4060c7aaac6f3a5c11ac5f67747d9a5.png',
  },
]);
const router = useRouter();
const {$analyticsTrack} = useNuxtApp();

watch(() => props.companyLogoCarousel, () => {
  setActiveCarouselItems();
});

setActiveCarouselItems();

/**
 * @_MOUNTED Hook
 */
onMounted(() => {
  setActiveCarouselItems();
});

/**
 * Set the active carousel items
 */
function setActiveCarouselItems () {
  if (props.companyLogoCarousel.length > 0) {
    activeCarouselItems.value = props.companyLogoCarousel;
  } else {
    activeCarouselItems.value = fallbackCarouselItems.value;
  }

  nextTick(() => {
    animateCarousel();
  });
}

/**
 * Animate the carousel
 */
function animateCarousel () {
  let firstCarouselItem = carouselItemsRef.value?.firstChild;
  while (firstCarouselItem && firstCarouselItem.nodeType !== Node.ELEMENT_NODE) {
    firstCarouselItem = firstCarouselItem.nextSibling;
  }
  if (!firstCarouselItem) return;
  const carouselItemWidth = (firstCarouselItem as HTMLElement).clientWidth;
  translateX.value -= carouselItemWidth;

  if (carouselItemsRef.value && carouselWrapperRef.value) {
    if (Math.abs(translateX.value) + carouselWrapperRef.value.clientWidth > carouselItemsRef.value.scrollWidth + 20) {
      translateX.value = 0;
    }
  }

  setTimeout(() => {
    animateCarousel();
  }, 3000);
}

/**
 * Open the company profile
 *
 * @param slug
 */
function openProfile (slug: string) {
  // Track analytics event
  $analyticsTrack({
    action: 'click',
    property: 'builder',
    entity: 'Company',
    entitySlug: slug,
    displayedLocation: 'Home page',
    eventData: {
      userIntention: 'passive',
      component: 'Logo Carousel',
    },
  });
  router.push(`/builder/${slug}/view`);
}
</script>

<style lang='scss'>
.sections-brand-carousel {
  background: rgb(var(--background-lighter-2));

  .__brand-carousel-wrapper {
    overflow: hidden;
    display: inline-flex;
    max-width: 100%;
    position: relative;

    &:before,
    &:after {
      background: rgb(251,251,251);
      content: '';
      position: absolute;
      width: 40px;
      height: 100%;
      z-index: 1;
    }

    &:before {
      background: linear-gradient(90deg, rgba(251,251,251,1) 50%, rgba(255,255,255,0) 100%);
      left: 0;
    }

    &:after {
      background: linear-gradient(270deg, rgba(251,251,251,1) 50%, rgba(255,255,255,0) 100%);
      right: 0;
    }

    .__brand-carousel-inner {
      margin-left: -25px;
      padding: 30px 0;
      display: inline-flex;
      align-items: center;
      transition: all 0.4s ease-in-out;

      .__brand-carousel-item {
        width: 180px;
        height: 50px;
        padding: 0 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.05);
          transition: all 0.2s ease-in-out;
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
      }

      // RESPONSIVE
      // ---------------------------------------------------------------------------------------------------------------
      @media screen and (max-width: $grid-sm) {
        margin-left: 5px;
      }
    }
  }
}
</style>
