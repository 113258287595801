<template>
  <div class="el-inputs-region-select container" data-svgmap>
    <div class="row">
      <!-- SVG map section -->
      <div class="col-md-12 col-lg-6 __map-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 907 842">
          <g v-for="(region, index) in nzRegions" :key="`map-${index}`" stroke="#FFF">
            <a :class="{'--selected': selectedRegionId === region.id, '--hover': hoverRegionId === region.id}" href="javascript:void(0);" @click="setSelectedRegion(region.id);" @mouseover="toggleHoverRegion(region.id);" @mouseout="toggleHoverRegion(region.id);">
              <path :d="region.svgD" />
            </a>
          </g>
        </svg>
      </div>

      <!-- Region list section -->
      <div class="col-md-12 col-lg-6 __region-title-list">
        <div class="row">
          <div class="col-md-12">
            <ElementsHeadingsDefault heading="Search by Region" sub-heading="Search" />
          </div>
          <div class="col-md-6">
            <h4>North Island</h4>
            <ul class="__north-island-list">
              <li v-for="(region, northIndex) in northRegionList" :key="`north-list-${northIndex}`" @click="setSelectedRegion(region.id);" @mouseover="toggleHoverRegion(region.id);" @mouseout="toggleHoverRegion(region.id);">
                <a :class="{'--selected': selectedRegionId === region.id, '--hover': hoverRegionId === region.id}" href="javascript:void(0);">{{ region.label }}</a>
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <h4>South Island</h4>
            <ul class="__south-island-list">
              <li v-for="(region, southIndex) in southRegionList" :key="`south-list-${southIndex}`" @click="setSelectedRegion(region.id);" @mouseover="toggleHoverRegion(region.id);" @mouseout="toggleHoverRegion(region.id);">
                <a :class="{'--selected': selectedRegionId === region.id, '--hover': hoverRegionId === region.id}" href="javascript:void(0);">{{ region.label }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {Ref} from 'vue';
import nzRegions from '~/composables/nzRegions';

// DATA
const selectedRegionId: Ref<string | null> = ref(null);
const hoverRegionId: Ref<string | null> = ref(null);
const northRegionList: Ref<{label: string, id: string, svgD: string, mapBounds: string, island: string}[]> = ref([]);
const southRegionList: Ref<{label: string, id: string, svgD: string, mapBounds: string, island: string}[]> = ref([]);

// PROPS
const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
});

// EMIT Definitions
const emit = defineEmits<{
  (event: 'update:modelValue', payload: {regionId: string; mapBounds: string}): void;
}>();

/**
 * Component MOUNTED!
 */
onMounted(() => {
  northRegionList.value = nzRegions.filter((nzRegion) => nzRegion.island === 'north');
  southRegionList.value = nzRegions.filter((nzRegion) => nzRegion.island === 'south');
});

/**
 * Set the selected region
 *
 * @param regionId
 */
function toggleHoverRegion (regionId: string) {
  hoverRegionId.value = hoverRegionId.value === regionId ? null : regionId;
}

/**
 * Set the selected region
 *
 * @param regionId
 */
function setSelectedRegion (regionId: string) {
  selectedRegionId.value = regionId;
  emit('update:modelValue', {regionId, mapBounds: nzRegions.find((nzRegion) => nzRegion.id === regionId)?.mapBounds as string});
}
</script>

<style lang="scss">
.el-inputs-region-select {
  overflow-x: hidden;

  svg g a {
    path {
      fill: rgb(var(--background-lighter-1));
      transition: fill 0.3s 0.1s ease-in;
    }

    &.--selected {
      path {
        fill: rgb(var(--primary-dark)) !important;
        transition: fill 0.3s ease-out;
      }
    }

    &.--hover {
      path {
        fill: rgb(var(--primary-light));
        transition: fill 0.3s ease-out;
      }
    }
  }

  .__region-title-list {
    padding: 38px 12px;

    h4 {
      font-size: 18px;
      font-weight: 500;
      padding: 12px 0;
    }

    ul {
      padding-bottom: 24px;

      li {
        padding: 6px 0;

        a {
          text-decoration: none;
          font-weight: 300;
          color: rgb(var(--text-default));
          transition: color 0.2s ease-out;

          &.--selected {
            color: rgb(var(--accent)) !important;
            transition: color 0.2s ease-out;
          }

          &.--hover {
            color: rgb(var(--accent));
            transition: color 0.2s ease-out;
          }
        }
      }
    }
  }

  @media screen and (min-width: $grid-lg) {
    .__map-svg {
      svg {
        width: 175%;
        height: auto;
        margin-left: -120px;
        margin-top: -80px;
      }
    }
  }
}
</style>
