<template>
  <div class="home-page">
    <SectionsHomeBanner />
    <SectionsBrandCarousel :company-logo-carousel="content && content.companyLogoCarousel ? content.companyLogoCarousel : []" />
    <SectionsListingCategorized />
    <LazyAdAdBar ad-display-location="home_page_banner" />
    <SectionsCountingStastics :stats-block="content?.statsBlock" />
    <LazySectionsNewsLetterSubscribe />
    <LazySectionsVideoBanner :video-block="content.videoBlock" />
  </div>
</template>

<script setup lang="ts">
import {useFetch, useRuntimeConfig} from '#app';
import globalApiHeaders from '~/composables/globalApiHeaders';
import triggerSiteDownError from '~/composables/triggerSiteDownError';
import {ApiReturn} from '~/@types/common';
import {Ref} from 'vue';

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const config = useRuntimeConfig();
const content: Ref<object | null> = ref(null);
const route = useRoute();

// DATA Fetching
//----------------------------------------------------------------------------------------------------------------------
const {data} = await useFetch(() => '/public/cms/show/home', { baseURL: config.public.apiUrl, headers: globalApiHeaders() });
if (!data.value || (data.value as ApiReturn).code !== 200 || !(data.value as ApiReturn).data) {
  triggerSiteDownError();
} else {
  content.value = (data.value as ApiReturn).data.content;
}

/**
 * @_HEAD Hook
 */
useHead({
  meta: [
    {
      name: 'canonical',
      content: 'https://www.newhomes.co.nz' + route.fullPath,
    },
  ],
});

useSeoMeta({
  title: 'New Homes NZ - Discover House & Land Packages, Apartments & Builders',
  description: 'Find your dream new home at New Homes NZ. Browse house & land packages, modern home designs, apartments, and explore top builders in your area. Start your journey today!',
  ogTitle: 'New Homes NZ - Discover House & Land Packages, Apartments & Builders',
  ogDescription: 'Find your dream new home at New Homes NZ. Browse house & land packages, modern home designs, apartments, and explore top builders in your area. Start your journey today!',
  ogImage: 'https://store.newhomeshub.co.nz/static-assets/new-homes-og-image-v2.jpg',
  twitterCard: 'summary_large_image',
  twitterTitle: 'New Homes NZ - Discover House & Land Packages, Apartments & Builders',
  twitterDescription: 'Find your dream new home at New Homes NZ. Browse house & land packages, modern home designs, apartments, and explore top builders in your area. Start your journey today!',
  twitterImage: 'https://store.newhomeshub.co.nz/static-assets/new-homes-og-image-v2.jpg',
});
</script>

