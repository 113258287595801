<template>
  <section ref="sectionCountingStatisticsRef" class="sections-counting-statistics">
    <div class="container">
      <!-- Heading -->
      <div class="row">
        <div class="col-12 __sections-counting-statistics-heading">
          <h2>Helping more New Zealander's into a new home</h2>
        </div>
      </div>

      <!-- Statistics data -->
      <div class="row __statistics-blocks-wrapper">
        <div class="col-md-3 col-sm-6 __statistics-block-wrapper" :class="{'--visible': isSectionVisible}">
          <div class="__statistics-block">
            <div class="__statistics-block-circle-outer __statistics-circle">
              <div class="__statistics-block-circle-inner __statistics-circle">
                <div class="__statistics-block-inner __statistics-circle">
                  <span ref="statValue1Ref" class="__stat-value">0</span>
                  <!--                  <span class="__stat-value" :class="{'__animated-number': isSectionVisible}" style="&#45;&#45;from: 10; &#45;&#45;to: 7029; &#45;&#45;duration: 5s; &#45;&#45;timing: ease-out" />-->
                  <span class="__stat-label">Listings</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 __statistics-block-wrapper" :class="{'--visible': isSectionVisible}">
          <div class="__statistics-block">
            <div class="__statistics-block-circle-outer __statistics-circle">
              <div class="__statistics-block-circle-inner __statistics-circle">
                <div class="__statistics-block-inner __statistics-circle">
                  <span ref="statValue2Ref" class="__stat-value">0</span>
                  <span class="__stat-label">New home designs</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 __statistics-block-wrapper" :class="{'--visible': isSectionVisible}">
          <div class="__statistics-block">
            <div class="__statistics-block-circle-outer __statistics-circle">
              <div class="__statistics-block-circle-inner __statistics-circle">
                <div class="__statistics-block-inner __statistics-circle">
                  <span ref="statValue3Ref" class="__stat-value">0</span>
                  <span class="__stat-label">New communities</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 __statistics-block-wrapper" :class="{'--visible': isSectionVisible}">
          <div class="__statistics-block">
            <div class="__statistics-block-circle-outer __statistics-circle">
              <div class="__statistics-block-circle-inner __statistics-circle">
                <div class="__statistics-block-inner __statistics-circle">
                  <span ref="statValue4Ref" class="__stat-value">0</span>
                  <span class="__stat-label">Apartments</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {Ref} from 'vue';

// PROPS Definitions
//----------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  statsBlock: {
    type: Object,
    default:  () => {
      return {
        listings: 11962,
        newHomeDesigns: 20000,
        newCommunities: 300,
        apartments: 90,
      };
    },
  },
});

// DATA
const sectionCountingStatisticsRef: Ref<HTMLElement | null> = ref(null);
const isSectionVisible: Ref<boolean> = ref(false);
const statValue1Ref: Ref<HTMLElement | null> = ref(null);
const statValue2Ref: Ref<HTMLElement | null> = ref(null);
const statValue3Ref: Ref<HTMLElement | null> = ref(null);
const statValue4Ref: Ref<HTMLElement | null> = ref(null);

/**
 * @_MOUNTED Hook
 */
onMounted(() => {
  if (sectionCountingStatisticsRef) {
    document.addEventListener('scroll', checkIsInView);
  }
});

/**
 * Check whether this section inside the view or not
 */
function checkIsInView () {
  if (props.statsBlock && sectionCountingStatisticsRef.value && !isSectionVisible.value && isInView(sectionCountingStatisticsRef.value, 200)) {
    isSectionVisible.value = true;
    document.removeEventListener('scroll', checkIsInView);

    if (statValue1Ref.value) {
      animateValue(statValue1Ref.value, 0, props.statsBlock.listings, 3000);
    }
    if (statValue2Ref.value) {
      animateValue(statValue2Ref.value, 0, props.statsBlock.newHomeDesigns, 3000);
    }
    if (statValue3Ref.value) {
      animateValue(statValue3Ref.value, 0, props.statsBlock.newCommunities, 3000);
    }
    if (statValue4Ref.value) {
      animateValue(statValue4Ref.value, 0, props.statsBlock.apartments, 3000);
    }
  }
}

/**
 * Animate values
 *
 * @param el
 * @param start
 * @param end
 * @param duration
 */
function animateValue(el: HTMLElement, start: number, end: number, duration: number) {
  let startTimestamp = 0;
  const step = (timestamp: number) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);

    if (el) {
      el.innerHTML = (Math.floor(progress * (end - start) + start)).toString();
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    }
  };
  window.requestAnimationFrame(step);
}
</script>

<style lang="scss" scoped>
.sections-counting-statistics {
  background: rgb(var(--background-darken-1));
  color: rgb(var(--text-light));
  padding: 120px 0;

  .__sections-counting-statistics-heading {
    text-align: center;
  }

  .__statistics-blocks-wrapper {
    margin-top: 40px;

    .__statistics-block-wrapper {
      opacity: 0;
      transform: translate(-20px, 0);

      &.--visible {
        opacity: 1;
        transform: translate(0, 0);
        transition: all 0.3s ease-in;
        @include delayTransition(0.15s, 4);
    }

    .__statistics-block {
      width: fit-content;
      height: fit-content;
      margin: 0 auto;
      padding-top: 20px;
      }

      .__statistics-circle {
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .__statistics-block-circle-outer {
        width: 180px;
        height: 180px;
        background: rgb(34,36,67);
        background: linear-gradient(0deg, rgba(34,36,67,1) 0%, rgba(40,39,74,1) 90%);
      }

      .__statistics-block-circle-inner {
        width: 150px;
        height: 150px;
        background: rgb(30,38,71);
        background: linear-gradient(0deg, rgba(30,38,71,1) 0%, rgba(62,60,90,1) 90%);
      }

      .__statistics-block-inner {
        width: 120px;
        height: 120px;
        background: rgb(24,46,77);
        background: linear-gradient(0deg, rgba(24,46,77,1) 0%, rgb(100, 100, 124) 100%);
      }

      .__stat-value {
        font-size: 36px;
        font-weight: 700;
        padding-top: 8px;
      }

      .__stat-label {
        font-size: 12px;
        font-weight: 300;
        padding-top: 8px;
      }
    }
  }
}
</style>
