<template>
  <section class="sections-home-banner no-gutter">
    <div class="container">
      <div class=" __home-banner-inner">
        <h1>Discover New Zealand's top builders, showhomes, house and land packages and developments all in one website</h1>
        <BlocksPrimarySearch />
        <h2>New Zealand's only dedicated marketplace for top <span>builders, showhomes, house and land packages</span> and <span>developments</span> all in one website</h2>
      </div>
    </div>
    <div class="__street-background-image" />
  </section>
</template>

<style lang="scss" scoped>
.sections-home-banner {
  .__home-banner-inner {
    padding: 80px 140px;

    h1 {
      font-size: 38px;
      font-weight: 700;
      padding: 60px;
      text-align: center;
      color: rgb(var(--text-dark));
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    }

    h2 {
      padding: 60px;
      font-size: 20px;
      text-align: center;
      color: rgb(var(--text-lighter-1));

      span {
        color: rgb(var(--text-dark));
      }
    }

    // RESPONSIVE
    // -----------------------------------------------------------------------------------------------------------------
    @media screen and (max-width: $grid-xl) {
      padding: 40px 80px;

      h1 {
        padding: 60px 50px;
      }

      h2 {
        padding: 60px 40px;
      }
    }

    @media screen and (max-width: $grid-lg) {
      padding: 20px 12px;

      h1 {
        font-size: 28px;
        font-weight: 400;
        line-height: 32px;
        padding: 60px 0;
      }

      h2 {
        padding: 80px 0;
        font-size: 18px;
      }
    }

    @media screen and (max-width: $grid-md) {
      h1 {
        padding: 40px 0;
      }

      h2 {
        padding: 60px 0;
      }
    }
  }

  .__street-background-image {
    width: 100%;
    height: 100px;
    background: url("assets/images/backgrounds/street-wireframe.webp");
    background-size: 450px !important;
    background-repeat-y: no-repeat !important;
    background-repeat-x: repeat !important;
  }
}
</style>
