<template>
  <div class="blocks-primary-search">
    <!-- Tab Wrapper -->
    <div class="__primary-search-tab-wrapper">
      <ul>
        <li class="__tab-item" :class="{'--active': selectedTab === 'House & Land'}" @click="selectedTab = 'House & Land'">
          House & Land
        </li>
        <li class="__tab-item" :class="{'--active': selectedTab === 'Land'}" @click="selectedTab = 'Land'">
          Land
        </li>
        <li class="__tab-item" :class="{'--active': selectedTab === 'Showhome'}" @click="selectedTab = 'Showhome'">
          Showhomes
        </li>
        <li class="__tab-item" :class="{'--active': selectedTab === 'Design'}" @click="selectedTab = 'Design'">
          Designs
        </li>
        <li class="__tab-item" :class="{'--active': selectedTab === 'Builders'}" @click="selectedTab = 'Builders'">
          Builders
        </li>
        <li class="__tab-item" :class="{'--active': selectedTab === 'Developments'}" @click="selectedTab = 'Developments'">
          Developments
        </li>
      </ul>
    </div>

    <!-- Desktop view -->
    <div class="__primary-search-fields-wrapper __desktop" @keyup.enter.prevent>
      <ElementsInputsDropdown v-model="selectedTab" :items="propertyTypeItems" @click="closePopups" />
      <ElementsCityboundSearch v-if="selectedTab !== 'Builders'" v-model="mapBounds" class="__input-search" :placeholder="searchFieldPlaceholder" />
      <ElementsInputsText v-else v-model="searchKeyword" :placeholder="searchFieldPlaceholder" />
      <ElementsButtonsIcon v-if="!['Developments', 'Builders', 'Sub-division'].includes(selectedTab)" class="__action-icon-button __btn-slider-icon" :icon-src="sliderIcon" :is-active="isOtherSearchPopupOpen" @click="triggerOtherSearchPopup" />

      <!-- Development Types -->
      <ElementsInputsDropdown v-if="selectedTab === 'Developments'" v-model="selectedDevelopmentType" class="__filter-dropdown __property-type" :items="developmentTypeItems" />

      <!-- Builder Services -->
      <ElementsInputsDropdown v-if="selectedTab === 'Builders'" v-model="selectedService" class="__filter-dropdown __property-type" :items="services" />

      <ElementsButtonsIcon v-if="!['Builders', 'Design'].includes(selectedTab)" class="__action-icon-button __btn-nz-map-icon" :icon-src="nzIcon" :is-active="isRegionSelectorPopupOpen" @click="triggerRegionSelectorPopupOpen" />
      <ElementsButtonsRegular class="__action-primary-button" size="lg" label="Search" :prepend-icon="searchPrependIcon" @click="search" />
    </div>

    <!-- Mobile view -->
    <div class="__primary-search-fields-wrapper __mobile">
      <div class="__primary-search-top-row">
        <ElementsInputsDropdown v-model="selectedTab" :items="propertyTypeItems" />
        <ElementsButtonsIcon class="__action-icon-button __btn-slider-icon" :icon-src="sliderIcon" :is-active="isOtherSearchPopupOpen" @click="triggerOtherSearchPopup" />
        <ElementsButtonsIcon class="__action-icon-button __btn-nz-map-icon" :icon-src="nzIcon" :is-active="isRegionSelectorPopupOpen" @click="triggerRegionSelectorPopupOpen" />
      </div>
      <div class="__primary-search-bottom-row">
        <ElementsCityboundSearch v-if="selectedTab !== 'Builders'" v-model="mapBounds" class="__input-search" :placeholder="searchFieldPlaceholder" />
        <ElementsInputsText v-else v-model="searchKeyword" placeholder="Search property name, city or suburb..." />
        <ElementsButtonsRegular class="__action-primary-button" label="Search" :prepend-icon="searchPrependIcon" @click="search" />
      </div>
    </div>

    <!-- Other search popup -->
    <BlocksOtherSearchPopup v-model="listingQueryParams" :is-open="isOtherSearchPopupOpen" />

    <!-- Region select popup -->
    <div class="__region-selector" :class="{'--open': isRegionSelectorPopupOpen}">
      <ElementsInputsRegionSelect v-model="selectedRegion" @update:model-value="search" />
    </div>
  </div>
</template>

<script setup lang="ts">
import sliderIcon from  '~/assets/images/icons/slider.webp';
import nzIcon from  '~/assets/images/icons/nz.webp';
import {computed, ComputedRef, Ref} from 'vue';
import {DropdownItem} from '~/@types/common';
import searchPrependIcon from '~/assets/images/icons/search.webp';
import {useDevelopmentStore} from '~/store/development';
import {useCompanyStore} from '~/store/company';
import {propertyTypes} from '~/composables/propertyTypes';
import ListingSearchQueryHandler, {ListingSearchRouteQuerySetQuery} from '~/composables/listingSearchQuery';
import {ListingType} from '~/@types/listing';

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const developmentStore = useDevelopmentStore();
const companyStore = useCompanyStore();
const router = useRouter();
const selectedTab: Ref<string> = ref('House & Land');
const searchKeyword: Ref<string> = ref('');
const mapBounds: Ref<string> = ref('');
const isOtherSearchPopupOpen: Ref<boolean> = ref(false);
const isRegionSelectorPopupOpen: Ref<boolean> = ref(false);
const selectedRegion: Ref<{regionId: string; mapBounds: string} | null> = ref(null);
const propertyTypeItems: Ref<DropdownItem[]> = ref([...propertyTypes, ...[{label: 'Developments', value: 'Developments'}, {label: 'Sub-divisions', value: 'Sub-division'}, {label: 'Builders', value: 'Builders'}]]);
const selectedDevelopmentType: Ref<string | null> = ref(null);
const listingQueryParams: Ref<ListingSearchRouteQuerySetQuery> = ref({});
const developmentTypeItems: Ref<DropdownItem[]> = ref([
  {label: 'All', value: null},
  {label: 'Sub-division', value: 'Sub-division'},
  {label: 'Apartment', value: 'Apartment'},
]);
const selectedService: Ref<string> = ref('');
const services: Ref<{ label: string; value: string }[]> = ref([
  {label: 'All Services', value: ''},
  {label: 'Land', value: 'Land'},
  {label: 'Apartment', value: 'Apartment'},
  {label: 'House & Land', value: 'House & Land'},
  {label: 'Ready to Move in', value: 'Ready to Move in'},
  {label: 'Turn Key', value: 'Turn Key'},
  {label: 'Affordable Housing', value: 'Affordable Housing'},
  {label: 'Design and Build', value: 'Design and Build'},
  {label: 'Showhome', value: 'Showhome'},
  {label: 'Investment', value: 'Investment'},
  {label: 'Terraced Housing', value: 'Terraced Housing'},
  {label: 'Architectural builder', value: 'Architectural builder'},
]);

/**
 * Trigger other search popup
 */
function triggerOtherSearchPopup () {
  isRegionSelectorPopupOpen.value = false;
  isOtherSearchPopupOpen.value = !isOtherSearchPopupOpen.value;
}
/**
 * Trigger other search popup
 */
function triggerRegionSelectorPopupOpen () {
  isOtherSearchPopupOpen.value = false;
  isRegionSelectorPopupOpen.value = !isRegionSelectorPopupOpen.value;
}

/**
 * Close popups when dropdown opens
 */
function closePopups () {
  isRegionSelectorPopupOpen.value = false;
  isOtherSearchPopupOpen.value = false;
}

/**
 * Trigger search and navigate the request to correct function
 */
function search () {
  switch (selectedTab.value) {
    case 'Design':
      searchListing();
      break;
    case 'Builders':
      searchCompanies();
      break;
    case 'Developments':
      searchDevelopments();
      break;
    case 'Sub-division':
      searchDevelopments('Sub-division');
      break;
    default:
      searchListing();
      break;
  }
}

/**
 * Search listing
 */
function searchListing () {
  ListingSearchQueryHandler.set({
    mapBounds: mapBounds.value,
    listingTypes: [selectedTab.value] as ListingType[],
    ...listingQueryParams.value,
    ...(selectedRegion.value?.mapBounds && {mapBounds: `(${selectedRegion.value.mapBounds})`}),
  }, '/listing/search');
}

/**
 * Search developments
 */
function searchDevelopments (forcedDevelopmentType?: string) {
  const params = {
    mapBounds: mapBounds.value,
    developmentType: forcedDevelopmentType ? forcedDevelopmentType : selectedDevelopmentType.value,
    ...(selectedRegion.value?.mapBounds && {mapBounds: `(${selectedRegion.value.mapBounds})`}),
    page: 1,
  };

  developmentStore.setUrlParamObject(params);
  router.push({
    path: '/developments/search',
    query: params,
  });
}

/**
 * Search companies
 */
function searchCompanies () {
  const params = {
    keyword: searchKeyword.value,
    selectedService: selectedService.value,
    perPage: companyStore.companytData.request_params.per_page,
    page: companyStore.companytData.page,
  };

  companyStore.setUrlParamObject(params);
  router.push({
    path: '/builder/search',
    query: params,
  });
}

/**
 * Search field placeholder
 */
const searchFieldPlaceholder: ComputedRef<string> = computed(() => {
  switch (selectedTab.value) {
    case 'Developments':
      return 'Region, city or suburb...';
    case 'Builders':
      return 'Type builder name, region or suburb...';
    default:
      return 'Region, city or suburb...';
  }
});
</script>

<style lang="scss">
.blocks-primary-search {
  position: relative;

  .address-wrapper.__input-search {
    width: 100%;
  }

  .__primary-search-tab-wrapper {
    z-index: 1;

    .__tab-item {
      display: inline-flex;
      background: rgb(var(--background-light));
      height: 45px;
      padding: 12px 16px;
      border-radius: 8px 8px 0 0;
      cursor: pointer;
      font-weight: 500;
      color: rgb(var(--text-dark));
      box-shadow: 0px -2px 7px -4px rgba(0, 0, 0, 0.15);
      transition: all 0.25s ease-in-out;

      &:not(:last-child) {
        margin-right: 4px;
      }

      &.--active,
      &:hover {
        color: rgb(var(--text-light));
        background: rgb(var(--background-dark));
        transition: all 0.25s ease-in-out;
      }
    }
  }

  .__primary-search-fields-wrapper {
    &.__mobile {
      display: none;
    }

    &.__desktop {
      display: inline-flex;
    }

    width: 100%;
    height: 55px;
    margin-top: -5px;
    background: rgb(var(--background-light));
    box-shadow: 0 1px 12px -3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    z-index: 9;

    .el-input-dropdown {
      max-height: 100%;
      width: 220px;
      border-right: 1px solid rgba(var(--text-lighter-1), 0.3);

      .__input-dropdown-inner {
        height: 55px;
      }
    }

    .__action-icon-button {
      display: flex;
      align-items: center;
      padding: 0 16px;
      border-left: 1px solid rgba(var(--text-lighter-1), 0.3);
      height: 100%;
    }

    .__action-primary-button {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .__region-selector {
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    width: 98%;
    margin: 0 auto;
    max-height: 0;
    overflow: hidden;
    height: fit-content;
    background: rgb(var(--background-light));
    box-shadow: 0 6px 8px -4px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 0 solid rgba(var(--text-lighter-1), 0.3);
    transform: translate(0, -50px);
    scale: 0.5;
    opacity: 0;
    transition: all 0.2s ease-in;

    &.--open {
      border-top: 1px solid rgba(var(--text-lighter-1), 0.3);
      padding: 30px 40px;
      max-height: 540px;
      scale: 1;
      transform: translate(0, 0);
      transition: all 0.2s ease-in;
      opacity: 1;
    }
  }

  .__filter-dropdown {
    border-left: 1px solid rgba(var(--text-lighter-1), 0.3);
  }

  .block-other-search-item-block {
    padding-top: 30px;
  }

  // RESPONSIVENESS
  // -------------------------------------------------------------------------------------------------------------------
  @media screen and (max-width: $grid-lg) {
    .__region-selector.--open {
      overflow: auto;
      padding: 0 12px;

      .el-inputs-region-select {
        margin-top: -38px;

        .__region-title-list {
          padding: 0 12px;
          margin-top: -32px;
        }
      }
    }

    .__primary-search-tab-wrapper {
      li.__tab-item {
        padding: 12px 14px;
      }
    }
  }

  @media screen and (max-width: $grid-md) {
    .__primary-search-tab-wrapper {
      display: none;
    }

    .__primary-search-fields-wrapper {
      height: 100px;

      &.__mobile {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;

        .__primary-search-top-row,
        .__primary-search-bottom-row {
          display: inline-flex;

          .el-input-dropdown {
            height: 50px;
            width: 80%;
          }

          .el-icon-button {
            width: 67px;
          }

          .el-input-text {
            border-top: 1px solid rgba(var(--text-lighter-1), 0.3);
          }

          .el-button-regular {
            border-top-right-radius: unset;
            height: 50px;
            width: 140px;

          }
        }
      }

      &.__desktop {
        display: none;
      }
    }
  }

  @media screen and (max-width: $grid-sm) {
    .__region-selector.--open {
      overflow: auto;
      padding: 20px;

      .el-inputs-region-select {
        .__map-svg {
          text-align: center;

          svg {
            width: 460px;
            margin-left: -80px;
          }
        }

        .__region-title-list {
          padding: 12px;
        }
      }
    }

    .__primary-search-fields-wrapper.__mobile {
      .__primary-search-top-row,
      .__primary-search-bottom-row {
        .el-icon-button {
          width: 51px;
        }

        .el-input-text {
          .__input-text-inner {
            padding-right: 0;
          }

          input::-webkit-input-placeholder {
            font-size: 13px;
          }
        }

        .el-button-regular {
          width: 102px;
          padding: 8px;
        }
      }
    }
  }
}
</style>
